h1 {
  font-size: 62px; }
h2 {
  font-size: 32px;
  @media (max-width: 991px) {
    font-size: 30px; }
  @media (max-width: 575px) {
    font-size: 28px; } }
h3 {
  font-size: 24px; }
h4 {
  font-size: 22px;
  @media (max-width: 767px) {
    font-size: 20px; } }
h5 {
  font-size: 20px;
  @media (max-width: 767px) {
    font-size: 18px; } }
h6 {
  font-size: 18px; }
h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  color: $heading-color;
  font-weight: 500;
  margin: 0;
  line-height: 1.3;
  word-break: break-word; }
h1>a, h2>a, h3>a, h4>a, h5>a, h6>a {
  font-family: $heading-font;
  color: $heading-color;
  font-weight: 500;
  @include transition(all 0.3s);
  line-height: 1.3;
  word-break: break-word; }
p, li, span {
  margin: 0; }
a {
  text-decoration: none;
  display: inline-block;
  font-family: $para-font;
  font-weight: 400; }
a:hover {
  text-decoration: none; }


.font-size--18px {
  font-size: 18px !important; }
.font-size--16px {
  font-size: 16px !important; }
.font-size--14px {
  font-size: 14px !important; }
.font-size--12px {
  font-size: 12px !important; }
