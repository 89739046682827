/* table css start */
.table {
  &.white-space-nowrap {
    white-space: nowrap; }
  thead {
    th {
      border-bottom: 1px solid #f0f1f5;
      border-top: none;
      padding: 20px;
      color: #7e7e7e;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      &:first-child {
        text-align: left; }
      &:last-child {
        text-align: right; } } }
  tbody {
    td {
      border-top: none;
      border-bottom: 1px solid #f0f1f5;
      padding: 12px 20px;
      color: #7e7e7e;
      text-align: center;
      &:first-child {
        text-align: left; }
      &:last-child {
        text-align: right; } }
    tr {
      @include transition(all 0.3s);
      &:hover {
        @include transform(translateY(-5px));
        box-shadow: 0 5px 15px rgba(#000000, 0.05);
        background-color: #ffffff;
        td {
          border-bottom-color: transparent; } }
      &:last-child {
        td {
          border-bottom: none; } } } }
  &.style--two {
    border-spacing: 0 15px;
    border-collapse: separate;
    border-radius(8px) {}

    thead {
      background-color: $base-color-two;
      tr {
        th {
          border-top: none;
          border-bottom: none;
          color: #ffffff;
          text-align: center;
          padding: 10px 20px;
          &:first-child {
            text-align: left; }
          &:last-child {
            text-align: right; } } } }
    tr {
      th, td {
        font-size: 14px; } }
    tbody {
      tr {
        background-color: #ffffff;
        box-shadow: 0 0 5px rgba(#000000, 0.09);
        @include transition(all 0.3s);
        &:hover {
          box-shadow: 0 0 25px rgba(#000000, 0.12);
          @include transform(translateY(-5px)); }
        td {
          border-top: none;
          text-align: center;
          color: $para-color;
          padding: 20px 20px;
          vertical-align: middle;
          font-size: 15px;
          &:first-child {
            text-align: left; }
          &:last-child {
            text-align: right; } }
        &:first-child {
          td {
            border-top: none; } } } } }
  .user {
    display: flex;
    align-items: center;
    .thumb {
      width: 45px;
      height: 45px;
      border: 2px solid #ffffff;
      overflow: hidden;
      @include border-radius(50%);
      box-shadow: 0 5px 10px rgba(#000000, 0.15);
      margin-right: 10px;
      img {
        width: inherit;
        height: inherit; } } }
  &.style--three {
    th, td {
      border: none !important;
      font-size: 14px;
      padding: 5px 15px;
      &:first-child {
        padding-left: 0; }
      &:last-child {
        padding-right: 0; } } } }
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #6e41ff0f; }

.badge {
  font-weight: 400;
  padding: 5px 12px;
  font-size: 13px;
  @include border-radius(999px);
  &.badge-primary {
    &.style--light {
      background-color: #145bcd33;
      color: $base-color; } }
  &.badge-secondary {
    &.style--light {
      background-color: #0000001f;
      color: #000000; } }
  &.badge-success {
    &.style--light {
      background-color: #28c76f24;
      color: #28c76f; } }
  &.badge-danger {
    &.style--light {
      background-color: #ea545533;
      color: #ea5455; } }
  &.badge-warning {
    &.style--light {
      background-color: #ff9f4333;
      color: #ff9f43; } }
  &.badge-info {
    &.style--light {
      background-color: #a4e3f180;
      color: #1EA7C5; } }
  &.badge-dark {
    &.style--light {
      background-color: #366636;
      color: #ffffff; } } }
.icon-btn {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  @include border-radius(3px);
  box-shadow: 0 3px 5px rgba(#000000, 0.25);
  font-size: 14px; }
// table responsive
[data-label] {
  position: relative;
  &::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #000000;
    top: 0;
    left: 0;
    padding: 13px 15px;
    display: none;
    font-size: 12px; } }
.table-responsive--lg {
  @media (max-width: 1199px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #007bff08; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
 } }        // border-top: 1px solid #dee2e6 !important
    .user {
      justify-content: flex-end; }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--md {
  @media (max-width: 991px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #007bff08; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
 } }        // border-top: 1px solid #dee2e6 !important
    .user {
      justify-content: flex-end; }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--sm {
  @media (max-width: 767px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #007bff08; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
 } }        // border-top: 1px solid #dee2e6 !important
    .user {
      justify-content: flex-end; }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--xs {
  @media (max-width: 575px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #007bff08; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
 } }        // border-top: 1px solid #dee2e6 !important
    .user {
      justify-content: flex-end; }
    [data-label] {
      &::before {
        display: block; } } } }
*[class*="table-responsive--"] {
  &.data-label--none {
    @media (max-width: 1199px) {
      tr {
        th, td {
          padding-left: .75rem; } } } } }
// *[class*="table-responsive--"]
//   .table-dark
//     tbody
//       [data-label]
//         &::before
//           color: $para-color
// .table-responsive--lg
//   .table-dark
//     @media (max-width: 1199px)
//       tbody
//         tr
//           &:nth-child(odd)
//             background-color: #343a40
//           &:nth-child(even)
//             background-color: #222930
// .table-responsive--md
//   .table-dark
//     @media (max-width: 991px)
//       tbody
//         tr
//           &:nth-child(odd)
//             background-color: #343a40
//           &:nth-child(even)
//             background-color: #222930
// .table-responsive--sm
//   .table-dark
//     @media (max-width: 767px)
//       tbody
//         tr
//           &:nth-child(odd)
//             background-color: #343a40
//           &:nth-child(even)
//             background-color: #222930
// .table-responsive--xs
//   .table-dark
//     @media (max-width: 575px)
//       tbody
//         tr
//           &:nth-child(odd)
//             background-color: #343a40
//           &:nth-child(even)
//             background-color: #222930
/* table css end */
