@import "./_reset";
/* global css strat */

// margin global css
.mt-30 {
  margin-top: 30px; }
.mb-30 {
  margin-bottom: 30px; }
.mb-50 {
  margin-bottom: 50px; }
.mb-none-30 {
  margin-bottom: -30px; }
.mb-none-50 {
  margin-bottom: -50px; }

.pt-100 {
  padding-top: 100px; }
.pb-100 {
  padding-bottom: 100px; }

.bg_img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.base--bg {
  background-color: $base-color !important; }
.base--bg-two {
  background-color: $base-color-two !important; }

.border-radius--5 {
  @include border-radius(5px !important); }
.border-radius--8 {
  @include border-radius(8px !important); }
.border-radius--20 {
  @include border-radius(20px !important); }
.border-radius--100 {
  @include border-radius(50% !important); }
.z-index-2 {
  z-index: 2; }

.base--color {
  color: $base-color !important; }

.para-white {
  color: #e6e6e6!important; }
.section--bg {
  background-color: #fdfdfd; }
.overlay--one {
  position: relative;
  z-index: 1;
  &::after {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.45;
    z-index: -1; } }
.white-glass-bg {
  background-color: rgba($base-color-two, 0.35);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); }
.glass--shadow {
  box-shadow: 0 5px 35px rgba(#000000, 0.25); }
.slick-arrow {
  cursor: pointer; }
.section-header {
  margin-bottom: 45px;
  margin-top: -8px; }
.section-top-title {
  color: $base-color;
  font-size: 14px;
  text-transform: uppercase; }
.section-title {
  font-size: 42px;
  @media (max-width: 1199px) {
    font-size: 36px; }
  @media (max-width: 991px) {
    font-size: 32px; }
  @media (max-width: 767px) {
    font-size: 28px; }
  &.has--border {
    position: relative;
    padding-bottom: 10px;
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 50px;
      height: 2px;
      background-color: $base-color; } } }
.section-title + p {
  margin-top: 15px; }
.hover--effect-1 {
  @include transition(all 0.3s);
  box-shadow: $shadow;
  &:hover {
    @include transform(translateY(-5px));
    box-shadow: $shadow-two; } }
.cmn-list {
  li + li {
    margin-top: 15px; }
  li {
    position: relative;
    padding-left: 40px;
    &::before {
      position: absolute;
      left: 0;
      top: 4px;
      content: '\f00c';
      font-family: 'Line Awesome Free';
      font-weight: 900;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      background-color: $base-color;
      color: #ffffff;
      font-size: 12px;
      @include border-radius(50%); } } }

.card {
  box-shadow: 0px 12px 23px 0px rgba(160, 44, 250, 0.04);
  background-color: #ffffff;
  border: none;
  @include border-radius(15px);
  overflow: hidden;
  .card-header {
    background-color: #ffffff;
    padding: 20px 30px;
    border-color: #f0f1f5;
    .card-title {
      margin: 0;
      font-size: 24px; } } }
hr {
  margin: 30px 0; }
.badge {
  font-weight: 400;
  padding: 4px 10px; }
.pagination {
  margin-top: 50px;
  li+li {
    margin-left: 10px; }
  li {
    &.active {
      a {
        background-color: $base-color;
        border-color: $base-color;
        color: #ffffff; } }
    a {
      border: 1px solid $border-color;
      @include border-radius(3px!important);
      padding: 12px 20px;
      background-color: transparent;
      color: $para-color;
      font-weight: 700;
      &:hover {
        background-color: $base-color;
        border-color: $base-color;
        color: #ffffff; } } } }
.cmn-accordion {
  box-shadow: 0 5px 25px rgba($base-color, 0.15);
  .card {
    background-color: #ffffff;
    @include border-radius(0 !important);
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #e5e5e5 !important;
    &:last-child {
      border-bottom: none !important; } }
  .card-header {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0!important;
    border: none;
    @include border-radius(0!important);
    .btn {
      background-color: #ffffff;
      padding: 20px 20px;
      display: inline-flex;
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      align-items: center;
      &:hover, &:focus {
        text-decoration: none;
        box-shadow: none; }
      &[aria-expanded="true"] {
        border-bottom-color: $base-color;
        background-color: rgba($base-color, 0.08);
        i {
          &::before {
            content: "\f068"; } } }
      &[aria-expanded="false"] {
        i {
          &::before {
            content: "\f067"; } } }
      i {
        color: $heading-color;
        font-size: 24px; }
      span {
        color: $heading-color;
        font-size: 18px;
        padding-left: 15px;
        @media (max-width: 767px) {
          font-size: 16px; } } } }
  .card-body {
    border-top: 1px solid #e5e5e5;
    padding: 20px 30px; } }

blockquote {
  p {
    font-weight: 700;
    font-size: 24px;
    color: #ffffff; } }
.page-breadcrumb {
  @include d-flex;
  margin-top: 15px;
  li {
    color: rgba(#ffffff, 0.8);
    text-transform: capitalize;
    &::after {
      content: '-';
      color: #ffffff;
      margin: 0 5px; }
    &:first-child {
      &::before {
        content: "\f015";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $base-color;
        margin-right: 6px; } }
    &:last-child {
      &::after {
        display: none; } }
    a {
      color: #ffffff;
      text-transform: capitalize;
      &:hover {
        color: $base-color; } } } }

.scroll-to-top {
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  background-color: $base-color;
  line-height: 77px;
  box-shadow: 0 5px 15px 0 rgba(#000000, 0.25);
  .scroll-icon {
    font-size: 31px;
    color: #ffffff;
    display: inline-block;
    i {
      @include transform(rotate(-45deg)); } } }

a[class*='lightcase-icon-'].lightcase-icon-close {
  top: 100px;
  right: 50px; }

// preloader css
@include keyframes(preloader-inside-white) {
  0% {
    @include transform(scale(0, 0)); }
  100% {
    @include transform(scale(1, 1)); } }
@include keyframes(preloader-inside-red) {
  0% {
    @include transform(scale(0, 0)); }
  30% {
    @include transform(scale(0, 0)); }
  100% {
    @include transform(scale(1, 1)); } }
.preloader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  text-align: center;
  .preloader-container {
    display: inline-block;
    width: 100px;
    height: 100px;
    position: relative; }
  .animated-preloader {
    display: inline-block;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: $base-color;
    @include border-radius(50%);
    &::after {
      content: '';
      display: inline-block;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      @include border-radius(50%);
      background: white;
      -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
      -ms-animation: preloader-inside-white 1s ease-in-out infinite;
      animation: preloader-inside-white 1s ease-in-out infinite; }
    &::before {
      content: '';
      display: inline-block;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      @include border-radius(50%);
      background: $base-color;
      -webkit-animation: preloader-inside-red 1s ease-in-out infinite;
      -ms-animation: preloader-inside-red 1s ease-in-out infinite;
      animation: preloader-inside-red 1s ease-in-out infinite; } } }
.breadcrumb-item {
  a {
    color: $base-color; } }
a[class*='lightcase-icon-'].lightcase-icon-close {
  top: 100px;
  right: 50px;
  z-index: 9999999999; }
#lightcase-overlay {
  z-index: 99999999; }

#lightcase-case {
  z-index: 999999999; }
/* global css end */
