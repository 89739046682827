/* hero css start */
.hero {
  padding-top: 370px;
  padding-bottom: 270px;
  background-position-y: top;
  position: relative;
  z-index: 1;
  @media (max-width: 1199px) {
    padding-top: 180px;
    padding-bottom: 120px; }
  @media (max-width: 575px) {
    padding-top: 150px;
    padding-bottom: 100px; }
  &::after {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.65;
    z-index: -1; }
  &__title {
    font-size: 72px;
    font-weight: 300;
    @media (max-width: 1199px) {
      font-size: 56px; }
    @media (max-width: 767px) {
      font-size: 42px; } }
  &__details {
    font-size: 18px;
    width: 80%;
    margin-left: auto;
    margin-right: auto; } }
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
/* hero css end */


/* inner hero section css start */
.inner-hero {
  padding-top: 180px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  &::after {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.75;
    z-index: -1; } }
/* inner hero section css end */
