/* feature section css start */
.feature-item-wrapper {
  padding: 50px 30px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px rgba(#000000, 0.05); }
.feature-card {
  &:hover {
    .feature-card__icon {
      animation: zoomIn 0.5s linear; } }
  &__icon {
    img {
      max-height: 65px; } }
  &__content {
    margin-top: 20px;
    .title {
      font-weight: 400;
      font-size: 16px; } } }
/* feature section css end */


/* ad format section css start */
.side-tab-nav {
  @include border-radius(5px);
  overflow: hidden;
  .nav-link {
    color: $para-color;
    padding: 15px 25px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include border-radius(0);
    @include transition(all 0.3s);
    i {
      margin-right: 8px;
      font-size: 28px; }
    &.active {
      background-color: $base-color;
      color: #ffffff;
      border-color: $base-color; } }
  &.style--white {
    .nav-link {
      color: #ffffff;
      background-color: rgba(#ffffff, 0.1);
      border-color: rgba(#ffffff, 0.25);
      &.active {
        background-color: $base-color;
        border-color: $base-color; } } } }
.tab-content {
  &.style--two {
    padding-left: 50px; } }
/* ad format section css end */


/* publisher & advertiser section css start */
.bottom-white-bg {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #bdc3c7;
    background: -webkit-linear-gradient(to top, #ffffff 5%, rgba(#ffffff, 0.05) 80%);
    background: linear-gradient(to top, #ffffff 5%, rgba(#ffffff, 0.05) 80%); } }
/* publisher & advertiser section css end */


/* worldwide section css start */
.worldwide-section {
  padding: 100px 0;
  background-position-y: top; }
.worldwide-title {
  font-size: 56px;
  font-weight: 30;
  @media (max-width: 991px) {
    font-size: 48px; }
  @media (max-width: 767px) {
    font-size: 36px; }
  @media (max-width: 575px) {
    font-size: 32px; }
  b {
    font-weight: 600; } }
.counter-item-wrapper {
  padding: 30px;
  background-color: rgba(#ffffff, 0.15);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  @include border-radius(10px);
  margin-top: 50px; }
.counter-item {}
.counter-card {
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column;
    text-align: center; }
  &__icon {
    i {
      font-size: 56px;
      color: $base-color;
      @media (max-width: 767px) {
        font-size: 48px; } } }
  &__content {
    padding-left: 15px;
    @media (max-width: 480px) {
      padding-left: 0;
      margin-top: 15px; } }
  &__amount {
    font-size: 32px;
    @media (max-width: 767px) {
      font-size: 28px; } } }
/* worldwide section css end */


/* choose us section css start */
.choose-card {
  @include transition(all 0.3s);
  height: 100%;
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    content: '';
    left: -15px;
    bottom: -15px;
    width: 35px;
    height: 35px;
    background-color: $base-color;
    @include border-radius(50%);
    opacity: 0.45;
    @include transition(all 0.3s); }
  &:hover {
    @include transform(translateY(-5px));
    &::before {
      opacity: 1; } }
  &__icon {
    img {
      height: 75px; } }
  &__content {
    margin-top: 30px; }
  &.style--two {
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 10px 35px rgba(#000000, 0.05);
    @include border-radius(5px);
    border: 1px solid transparent;
    &:hover {
      box-shadow: 0 15px 25px rgba(#000000, 0.1);
      background-color: rgba($base-color, 0.05);
      border-color: #e5e5e5; } } }
/* choose us section css end */


/* overview section css start */
.overview-section {
  padding-top: 100px;
  position: relative;
  z-index: 1;
  @media (max-width: 991px) {
    padding-bottom: 100px; }
  &::after {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.65;
    z-index: -1; } }
.video-part {
  .caption {
    font-weight: 300;
    font-size: 18px; } }
.video-icon {
  width: 95px;
  height: 95px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($base-color, 0.75);
  color: #ffffff;
  @include border-radius(50%);
  @include transition(all 0.3s);
  &:hover {
    background-color: $base-color-two;
    color: #ffffff; }
  i {
    font-size: 48px; } }

.testimonial-wrapper {
  padding: 50px 40px;
  background-color: $base-color-two;
  &.bottom-minus {
    margin-bottom: -130px;
    @media (max-width: 991px) {
      margin-bottom: 0; } }
  .title {
    font-weight: 300;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#ffffff, 0.15);
    margin-bottom: 20px; } }
.testimonial-card {
  .client {
    .thumb {
      width: 65px;
      height: 65px;
      overflow: hidden;
      @include border-radius(50%); }
    .content {
      padding-left: 20px; } } }
.testimonial-slider {
  .slick-dots {
    @include d-flex;
    justify-content: center;
    li {
      margin: 0 3px;
      &.slick-active {
        button {
          border-color: $base-color; } }
      button {
        font-size: 0;
        width: 10px;
        height: 10px;
        background-color: transparent;
        padding: 0;
        border: 2px solid rgba(#ffffff, 0.35);
        @include border-radius(50%);
        @include transition(all 0.3s); } } } }
/* overview section css end */


/* brand-section css start */
.brand-section {
  padding: 50px 0;
  background-color: $base-color; }
.brand-item {
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  img {
    display: inline-block;
    max-height: 50px; } }
.brand-slider {
  .slick-list {
    margin: 0 -15px; }
  .single-slide {
    margin: 0 15px; } }
/* brand-section css end */


/* contact section css start */
.contact-info-card {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 5px 35px rgba(#000000, 0.05);
  height: 100%;
  @include transition(all 0.3s);
  &:hover {
    box-shadow: 0 5px 15px rgba($base-color, 0.15); }
  &__icon {
    width: 75px;
    height: 75px;
    margin: 0 auto;
    background-color: $base-color;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    @include border-radius(50%);
    position: relative;
    z-index: 1;
    &::after {
      position: absolute;
      content: '';
      top: -7px;
      left: -7px;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      background-color: $base-color;
      opacity: 0.25;
      @include border-radius(50%);
      z-index: -1; }
    i {
      font-size: 36px; } }
  &__content {
    margin-top: 30px;
    .title {
      margin-bottom: 15px; }
    a {
      color: $para-color;
      &:hover {
        color: $base-color; } } } }

.contact-form-wrapper {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 5px 25px rgba(#000000, 0.08); }
.contact-map {
  width: 100%;
  height: 100%;
  @media (max-width: 991px) {
    min-height: 450px; } }
/* contact section css end */


/* account section css start */
.account-area {
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 10px 25px rgba(#000000, 0.1);
  @include border-radius(8px); }
.account-wrapper {
  padding: 50px 50px 50px 35px;
  @media (max-width: 991px) {
    padding: 30px; } }
.account-tab-nav {
  border-bottom: none;
  .nav-item {
    width: 50%;
    text-align: center;
    .nav-link {
      padding: 15px 30px;
      border: none !important;
      background-color: #f1f1f1;
      color: $para-color;
      @include border-radius(0);
      &.active {
        background-color: $base-color;
        color: #ffffff; } } } }
.account-content {
  .title {
    font-size: 32px; } }
/* account section css end */
