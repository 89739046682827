
$heading-color: #04223c;
$para-color: #6f6f6f;

$base-color: #16c79a;
$base-color-two: #062c4e;

// bg-color
$bg-one: #001d4a;
$bg-two: #02014a;

// border-color
$border-color: #e5e5e5;

// box-shadow
$shadow: 0px 25px 40px 0px rgba(51, 51, 51, 0.1);
$shadow-two: 0px 0px 15px 0px rgba(51, 51, 51, 0.05);
$shadow-three: 0px 25px 40px 0px rgba(51, 51, 51, 0.2);
