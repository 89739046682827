/* button css start */
.cmn-btn {
  padding: 12px 35px;
  @include border-radius(5px);
  background-color: $base-color;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  @include transition(all 0.3s);
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background-color: darken($base-color, 2%);
    box-shadow: 0 5px 15px 2px rgba($base-color, 0.45);
    @include transform(translateY(-3px)); }
  &.active {
    background-color: $bg-one; } }
.cmn-btn2 {
  padding: 12px 35px;
  @include border-radius(5px);
  background-color: $base-color-two;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  @include transition(all 0.3s);
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background-color: darken($base-color-two, 5%); } }
.border-btn {
  padding: 12px 35px;
  color: $para-color;
  @include border-radius(5px);
  border: 1px solid #e5e5e5;
  @include transition(all 0.3s);
  &:hover {
    background-color: $base-color;
    color: #ffffff;
    border-color: $base-color;
    box-shadow: $shadow; } }

.btn-group {
  @include d-flex;
  margin-left: -10px;
  margin-right: -10px;
  *[class*="btn"] {
    margin: 5px 10px;
    align-items: center;
    &.d-flex {
      padding: 8px 35px; } } }
[class*="btn"] {
  &.btn-md {
    padding: 10px 30px; }
  &.btn-sm {
    padding: 6px 20px; } }

.action-btn {
  padding: 5px 15px;
  font-weight: 600;
  background-color: #ffffff;
  color: $base-color;
  font-size: 14px;
  @include border-radius(5px); }
/* button css end */
